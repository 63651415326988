import * as React from "react";

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="51"
    fill="none"
    viewBox="0 0 51 51"
    className='hover:bg-base-300 rounded-full cursor-pointer'
  >
    <rect
      width="49"
      height="49"
      x="1"
      y="1"
      stroke="#838086"
      strokeWidth="2"
      rx="24.5"
    ></rect>
    <path
      className='stroke-base-100'
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M27.228 20.436 32.792 26l-5.565 5.564M17.208 26h15.428"
    ></path>
  </svg>
);

export default SvgIcon;

import React, { useState } from 'react';
import OpinionCard from './OpinionCard';
import Clients from '../assets/clients.webp';
import Michal from '../assets/michal.png';
import Anna from '../assets/Anna.webp';
import Krzysztof from '../assets/Krzysztof.webp';
import Tomasz from '../assets/Tomasz.webp';
import Lukasz from '../assets/Lukasz.webp';
import Ewelina from '../assets/Ewelina.webp';
import Magdalena from '../assets/Magda.webp';
import OpinionImage from '../assets/opinionBackground.png';
import { useTranslation } from 'react-i18next';

const opinions = [
  {
    name: "Anna",
    location: "Warszawa, Polska",
    rating: 5,
    review: "Świetna strona, kupno winiety zajęło mi dosłownie trzy minuty. Bez stresu, bez problemów – polecam!",
    avatar: Anna.src,
  },
  {
    name: "Krzysztof",
    location: "Wrocław, Polska",
    rating: 4,
    review: "Kupowanie winiet na tej stronie to czysta wygoda. Kiedyś zawsze martwiłem się, gdzie i jak kupić winietę, zwłaszcza podczas zagranicznych podróży. Teraz załatwiam wszystko online, zanim jeszcze spakuję samochód. System działa bezbłędnie, a ja mogę skupić się na trasie, zamiast na formalnościach.",
    avatar: Krzysztof.src,
  },
  {
    name: "Tomasz",
    location: "Kraków, Polska",
    rating: 5,
    review: "Bardzo wygodne rozwiązanie. Nie musiałem szukać winiety na stacji, wszystko załatwiłem online.",
    avatar: Tomasz.src,
  },
  {
    name: "Łukasz",
    location: "Katowice, Polska",
    rating: 4,
    review: "Jako osoba często podróżująca za granicę, muszę przyznać, że e-winiety to świetne rozwiązanie. Zawsze cenię sobie szybkie i proste usługi, a ten serwis spełnił wszystkie moje oczekiwania. Płatność była bezpieczna, a winieta aktywna od razu. Polecam każdemu kierowcy, który nie chce tracić czasu na stacjach benzynowych.",
    avatar: Lukasz.src,
  },
  {
    name: "Ewelina",
    location: "Poznań, Polska",
    rating: 5,
    review: "Nigdy wcześniej nie korzystałam z e-winiet, ale byłam mile zaskoczona. Cały proces zakupu jest bardzo intuicyjny, a obsługa klienta pomocna, gdy miałam pytania. Dużym plusem jest to, że mogłam załatwić wszystko z telefonu. Wybrałam winietę, zapłaciłam i już miałam ją na mailu. Na pewno skorzystam ponownie przy kolejnej podróży.",
    avatar: Ewelina.src,
  },
  {
    name: "Magdalena",
    location: "Gdańsk, Polska",
    rating: 4,
    review: "Nie spodziewałam się, że to będzie takie proste. Szybko, sprawnie, a winieta od razu działa.",
    avatar: Magdalena.src,
  },
];

const OpinionSection: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { t } = useTranslation('common');
  
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? opinions.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === opinions.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <section className="px-10 py-12">
      <div className='lg:max-w-[1200px] mx-auto flex flex-col md:flex-row items-center md:items-center lg:h-[750px]'>
        <div className="md:w-1/2 text-left pr-6 box-border">
          <div className='flex items-center space-x-3 mb-4'>
            <img src={Clients.src} alt="Ikona profilu" className="w-16" />
            <span className="text-[16px] font-[700] text-neutral leading-[21px]">{t('opinionSection.span1')}</span>
          </div>
          <h2 className="text-[45px] font-[700] leading-[61px] text-base-100 mb-4 mt-0 lg:w-3/4">
            {t('opinionSection.header1')}
          </h2>
          <p className="text-base-100 font-[400] text-[16px] leading-[35px] mb-6 lg:w-3/4">
            {t('opinionSection.text1')}
          </p>
        </div>

        <div className="md:w-1/2 md:flex-col justify-center">
            <div className="flex-shrink-0">
                <OpinionCard {...opinions[currentIndex]} />
            </div>
            <div className="flex space-x-4 mt-8">
          <button onClick={handlePrevious} className="bg-transparent border-0 rounded-full m-0 p-0">
            <svg className='hover:bg-base-300 rounded-full cursor-pointer' width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="-1" y="1" width="49" height="49" rx="24.5" transform="matrix(-1 0 0 1 49 0)" stroke="#242226" strokeWidth="2"/>
                <path d="M23.7725 20.4358L18.2083 26L23.7725 31.5641" className='stroke-base-100' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M33.7917 26H18.3642" className='stroke-base-100' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
          <button onClick={handleNext} className="p-0 m-0 bg-transparent rounded-full border-0">
            <svg className='hover:bg-base-300 rounded-full cursor-pointer' width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="1" width="49" height="49" rx="24.5" stroke="#242226" strokeWidth="2"/>
                <path d="M27.2276 20.4358L32.7917 26L27.2276 31.5641" className='stroke-base-100' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M17.2083 26H32.6358" className='stroke-base-100' strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
        </div>
      </div>
    </section>
  );
};

export default OpinionSection;
import React, { ReactNode } from 'react';

interface BenefitCardProps {
  title: string;
  description: string;
  higher: boolean;
  icon: ReactNode;
  additional?: string;
}

const BenefitCard: React.FC<BenefitCardProps> = ({ title, description, higher, icon, additional }) => {
  return (
    <div
      className={`${
        higher ? "bg-primary lg:mb-20" : "bg-transparent lg:mt-20"
      } rounded-[25px] p-6 flex flex-col min-h-[250px]`}
    >
      <div className="flex justify-start w-16 h-16">{icon}</div>

      <h3 className="text-[25px] font-[700] leading-[34px] text-base-100 mb-2">
        {title}
      </h3>
      <p className="text-[16px] font-[400] leading-[35px] text-base-100">
        {description}
      </p>
      {additional && (
        <p className="text-[16px] font-[700] mt-auto text-base-100">
          {additional}
        </p>
      )}
    </div>
  );
};

export default BenefitCard;

import BlogSection from '@/components/BlogSection';
import CountrySection from '@/components/CountrySection';
import CustomDatePicker from '@/components/CustomDatePicker';
import Footer from '@/components/Footer';
import HeroSection from '@/components/HeroSection';
import HistorySection from '@/components/HistorySection';
import InfoSection from '@/components/InfoSection';
import { ModalTrigger } from '@/components/ModalTrigger';
import Navbar from '@/components/Navbar';
import OpinionSection from '@/components/OpinionSection';
import TravelSection from '@/components/TravelSection';
import { modalIds } from '@/constants';
import Head from 'next/head';
import vectorSVG from "../assets/vectorsvg.svg";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentSection from '@/components/PaymentSection';
import VideoSection from '@/components/VideoSection';
// export const getServerSideProps = async ({ req }: { req: any }) => {
//   // Wykryj język z nagłówka przeglądarki lub ustaw domyślny
//   const acceptLanguage = req.headers['accept-language'] || '';
//   const preferredLanguage = acceptLanguage.split(',')[0]?.split('-')[0] || 'pl';

//   return {
//     props: {
//       language: preferredLanguage,
//     },
//   };
// };


export default function Home() {  
    const [isReady, setIsReady] = useState<boolean>(false);
    const { i18n } = useTranslation('common');
    useEffect(()=>{
      setIsReady(true);
  
    }, [i18n.isInitialized]);
  
    if (!i18n.isInitialized) {
      return null; // Możesz wyświetlić spinner lub placeholder
    }
    
  return (
    isReady ?
    (
      <>
        <Head>
          <title>Strona główna</title>
          <meta name="description" content="Zamów e-winiety online na winiety24.eu i podróżuj bez zbędnych formalności! Szybka aktywacja, bezpieczne płatności, wsparcie 24/7. Komfortowe podróże po Europie zaczynają się tutaj." />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <div className='bg-secondary'>
          <Navbar/>
          <div>
            <HeroSection/>
          </div>
          <div className='py-12 bg-secondary'>
            <CountrySection/>
          </div>
          <div className='pt-12 bg-primary'>
            <InfoSection/>
          </div>
          <div className='pb-12 bg-primary'>
            <VideoSection/>
          </div>
          {/* <div className='py-12 bg-secondary'>
            <HistorySection/>
          </div> */}
          <div className='py-12 bg-secondary min-h-[450px] overflow-hidden' id='zalety-ewiniet'>
            <TravelSection/>
          </div>
          <div className='bg-primary' style={{ backgroundImage: `url(${vectorSVG.src})`, backgroundRepeat: "no-repeat", backgroundPosition: "top left"}}>
            <OpinionSection/>
          </div>
          <div className='py-12 bg-secondary'>
            <BlogSection/>
          </div>
          <div className='bg-secondary'>
            <PaymentSection/>
          </div>
          <div>
            <Footer/>
          </div>
        </div>
      </>
    )
    :
    null
  );
}


import React, { useEffect, useState } from 'react';
import AustriaIcon from '../assets/austria.png';
import CzechIcon from '../assets/czechy.png';
import SzwajcariaIcon from "../assets/szwajcaria.png";
import WegryIcon from "../assets/wegry.png";
import RumuniaIcon from "../assets/rumunia.png";
import AustriaImg from '../assets/austriaImage.png';
import CzechImg from '../assets/czechImage.png';
import SzwajcariaImg from "../assets/szwajcariaImage.png";
import WegryImg from "../assets/wegryImage.png";
import RumuniaImg from "../assets/rumuniaImage.png";
import slovakia from '../assets/Slovakia.png';
import SlovakiaImng from '../assets/SlovakiaImg.png';
import bulgaria from '../assets/bulgaria.png';
import BulgariaImng from '../assets/bulgariaImg.png';
import slovenia from '../assets/slovenia.png';
import SloveniaImng from '../assets/sloveniaImg.png';
import ArrowButton from '@/assets/ArrowButton';
import YellowLine from './YellowLine';
import { useTranslation } from 'react-i18next';
import axios from "@/axiosConfig";

interface Country {
  name: string;
  flagUrl: string;
  imageUrl: string;
  search?: string;
  currency?: string;
  gasoline?: number;
  diesel?: number;
}

const CountrySection: React.FC = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const { t, i18n } = useTranslation('common');

  useEffect(() => {
    const fetchCountriesData = async () => {
      try {
        const response = await axios.get('/Cars/fuel-prices');
        const fuelData = response.data.data;

        const updatedCountries: Country[] = [
          { name: t('menu.austria'), search: 'Austria', flagUrl: AustriaIcon.src, imageUrl: AustriaImg.src },
          { name: t('menu.czechy'), search: 'Czech Republic', flagUrl: CzechIcon.src, imageUrl: CzechImg.src },
          { name: t('menu.szwajcaria'), search: 'Switzerland', flagUrl: SzwajcariaIcon.src, imageUrl: SzwajcariaImg.src },
          { name: t('menu.wegry'), search: 'Hungary', flagUrl: WegryIcon.src, imageUrl: WegryImg.src },
          { name: t('menu.rumunia'), search: 'Romania', flagUrl: RumuniaIcon.src, imageUrl: RumuniaImg.src },
          { name: t('menu.slowacja'), search: 'Slovakia', flagUrl: slovakia.src, imageUrl: SlovakiaImng.src },
          { name: t('menu.bulgaria'), search: 'Bulgaria', flagUrl: bulgaria.src, imageUrl: BulgariaImng.src },
          { name: t('menu.slowenia'), search: 'Slovenia', flagUrl: slovenia.src, imageUrl: SloveniaImng.src },
        ].map(country => {
          const matchingFuelData = fuelData.find((data: any) => data.country === country.search);
          if (matchingFuelData) {
            return {
              ...country,
              currency: matchingFuelData.currency,
              gasoline: matchingFuelData.gasoline,
              diesel: matchingFuelData.diesel,
            };
          }
          return country;
        });

        setCountries(updatedCountries);
      } catch (error) {
        console.error("Error fetching fuel prices:", error);
        let countries: Country[] = [
          { name: t('menu.austria'), search: 'Austria', flagUrl: AustriaIcon.src, imageUrl: AustriaImg.src },
          { name: t('menu.czechy'), search: 'Czech Republic', flagUrl: CzechIcon.src, imageUrl: CzechImg.src },
          { name: t('menu.szwajcaria'), search: 'Switzerland', flagUrl: SzwajcariaIcon.src, imageUrl: SzwajcariaImg.src },
          { name: t('menu.wegry'), search: 'Hungary', flagUrl: WegryIcon.src, imageUrl: WegryImg.src },
          { name: t('menu.rumunia'), search: 'Romania', flagUrl: RumuniaIcon.src, imageUrl: RumuniaImg.src },
          { name: t('menu.slowacja'), search: 'Slovakia', flagUrl: slovakia.src, imageUrl: SlovakiaImng.src },
          { name: t('menu.bulgaria'), search: 'Bulgaria', flagUrl: bulgaria.src, imageUrl: BulgariaImng.src },
          { name: t('menu.slowenia'), search: 'Slovenia', flagUrl: slovenia.src, imageUrl: SloveniaImng.src },
        ]
        setCountries(countries)
      }
    };

    if (i18n.isInitialized) {
      fetchCountriesData();
    }
  }, [i18n.isInitialized, t]);

  if (!i18n.isInitialized) {
    return null; // Możesz wyświetlić spinner lub placeholder
  }

  return (
    countries.length > 0 ? (
      <>
        <section className="py-12 lg:max-w-[1200px] p-6 lg:p-0 mx-auto" id='popularne'>
          <div className="lg:flex lg:justify-between lg:items-center mb-6 lg:space-x-12 space-y-10 lg:space-y-0">
            <div className="flex flex-col">
              <h2 className="text-[45px] font-[700] leading-[61px] min-h-[75px] text-base-100 mb-3">{t('countrySection.header1')}</h2>
              <YellowLine />
            </div>
            <div className="lg:w-1/2">
              <p className="text-base-100 text-[16px] leading-[35px]">{t('countrySection.text1')}</p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6 mt-12">
            {countries.map((country, index) => (
              <a href="/order" className="no-underline">
                <div key={index} className="bg-transparent rounded-[35px] border-2 border-solid border-base-300 flex items-center hover:bg-primary transition">
                  <img src={country.imageUrl} alt={country.name} className="w-40 min-h-[180px] mr-6 h-full" />
                  <div className="flex-1">
                    <div className="flex items-center">
                      <img src={country.flagUrl} alt={`Flaga ${country.name}`} className="w-8 h-8" />
                    </div>
                    <div className="flex items-center">
                      <h3 className="text-[25px] font-[700] leading-[34px] text-base-100 mb-4 mt-2">{country.name}</h3>
                    </div>
                    <div className="flex lg:flex-row flex-col lg:justify-between lg:pr-6 space-y-0 lg:space-y-4">
                      {country.gasoline && (
                        <p className="text-base-100 text-[16px] font-[600]">PB95: {country.gasoline.toFixed(2)} {country.currency}</p>
                      )}
                      {country.diesel && (
                        <p className="text-base-100 text-[16px] font-[600]">Diesel: {country.diesel.toFixed(2)} {country.currency}</p>
                      )}
                      {/* <div className="flex justify-between mt-2">
                        <p className="text-base-100 text-[16px] font-[700] leading-[21px] cursor-pointer">{t('countrySection.kup')}</p>
                        <ArrowButton />
                      </div> */}
                    </div>
                  </div>
                </div>
              </a>
            ))}
          </div>
        </section>
      </>
    ) : null
  );
};

export default CountrySection;

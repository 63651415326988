import React, { useEffect, useRef, useState } from 'react';
import heroImg from '../assets/homeBackground.webp';
import { useRouter } from 'next/router';
import { useVignetteContext } from '@/context/VignetteContext';
import CustomDatePicker from './CustomDatePicker';
import { useTranslation } from 'react-i18next';
import dynamic from 'next/dynamic';
import { toLocalISOString } from '@/utils/formattingFunctions';

const Localization = dynamic(() => import('@/assets/Localization'), { ssr: false });
const Calendar = dynamic(() => import('@/assets/Calendar'), { ssr: false });
const Phone = dynamic(() => import('@/assets/Phone'), { ssr: false });

const HeroSection: React.FC = () => {
  const {startDate, endDate, setStartDate, setEndDate, setSelectedMultiple} = useVignetteContext();
  const [isReady, setIsReady] = useState<boolean>(false);
  
  const { t, i18n } = useTranslation('common');
  useEffect(()=>{
    setIsReady(true);

  }, [i18n.isInitialized]);

  if (!i18n.isInitialized) {
    return null; 
  }

  //console.log(startDate);
  const router = useRouter();

  const handleSearchClick = () => {
    if(startDate.split("T")[0]<toLocalISOString(new Date()))
    {
      setStartDate(toLocalISOString(new Date()));
    }
    if(endDate.split("T")[0]<toLocalISOString(new Date()))
    {
      setEndDate(toLocalISOString(new Date()));
    }

    if(endDate.split("T")[0]<startDate.split("T")[0])
    {
      //let tmp = endDate;
      setEndDate(startDate);
      //setStartDate(tmp);
    }

    if(startDate == '' || endDate == '')
    {
      if(startDate == '')
      {
        setStartDate(toLocalISOString(new Date()));
      }
      if(endDate == '')
      {
        setEndDate(toLocalISOString(new Date()));
      }
      return;
    }

    router.push({
      pathname: '/order',
    });
  }


  useEffect(() => {
    if(endDate.split("T")[0]<startDate.split("T")[0])
    {
      //let tmp = endDate;
      setEndDate(startDate);
      //setStartDate(tmp);
    }
  }, [startDate, endDate]);

  const handleGoContact = () => {
    router.push("/contact");
  }

  const destinationToCountryIdMap: Record<string, number[] | null> = {
    czech: null,
    bulgaria: null,
    romania: null,
    slovakia: null,
    slovenia: null,
    switzerland: null,
    croatia: null,
    italy: null,
    austria_czech: [1, 2],
    austria_slovakia_hungary: [1, 6, 4],
    bulgaria_slovakia_hungary_romania: [7, 6, 4, 5],
    croatia_czech_slovakia_hungary: [2, 6, 4],
    croatia_slovakia_hungary: [6, 4],
    croatia_czech_austria_slovakia: [2, 1, 8],
    croatia_germany_austria_slovenia: [1, 8],
    romania_slovakia_hungary: [5, 6, 4],
    slovenia_czech_austria: [8, 2, 1],
    switzerland_czech_austria: [3, 2, 1],
    hungary_slovakia: [4, 6],
  };

  const handleDestinationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    const countryIds = destinationToCountryIdMap[selectedValue] || [];
    setSelectedMultiple(countryIds);
  };

  return (
      isReady ? 
      (
        <>
          <section id='hero'
            className="md:h-[750px] bg-cover bg-center flex flex-col justify-center items-center text-white h-[1000px]"
            style={{ backgroundImage: `url(${heroImg.src})` }}
          >
            <div className="text-center mb-10 p-4 lg:p-0">
              <h1 className="text-[25px] md:text-[65px] font-bold md:leading-[88px] mb-4 md:mt-0">
              {t('hero.header1')}<br />{t('hero.header2')}
              </h1>
              <p className="text-[16px] leading-[21px] font-normal max-w-2xl mx-auto">
              {t('hero.text1')}
              </p> 
            </div>
      
            <div className="bg-[#131313] bg-opacity-80 rounded-[25px] box-border lg:p-8 pt-6 pb-6 w-full xl:max-w-[1300px] shadow-lg">
              <div className="lg:ml-8 ml-14">
                <button className="text-black font-[700] py-4 px-10 rounded-t-[25px] bg-white border-0 text-[14px] leading-[19px]">
                {t('hero.winiety')}
                </button>
              </div>
      
              <div className="grid grid-cols-1 border-2 rounded-[25px] border-[#242226] mx-6 lg:mx-0 border-solid md:grid-cols-5 gap-4">
                <div className="border-0 lg:border-r-2 lg:border-b-0 border-b-2 border-[#242226] border-solid p-6 flex items-center space-x-4">
                  <Localization/>
                  <div>
                    <p className="text-[14px] text-neutral leading-[19px] font-[300] mb-1 mt-0">{t('hero.z')}</p>
                    <div className='flex items-center cursor-pointer'>
                      <select
                          className="selhome text-white text-[14px] font-[700] outline-none w-full appearance-none cursor-pointer text-lg"
                          style={{
                              border: "none",
                              width: "80px"
                          }}
                          >
                          <option style={{ backgroundColor: "#131313", fontSize: "16px" }}>{t('hero.option_17')}</option>
                      </select>
                    </div>
                  </div>
                </div>
      
                <div className="border-0 lg:border-r-2 lg:border-b-0 border-b-2 border-[#242226] border-solid lg:p-4 p-6 pt-6 lg:pt-6 flex items-center space-x-4">
                  <Localization/>
                  <div>
                    <p className="text-[14px] text-neutral leading-[19px] font-[300] mb-1 mt-0">{t('hero.cel')}</p>
                    <div className='flex items-center'>
                      <select
                          className="selhome text-white text-[14px] font-[700] outline-none w-full appearance-none cursor-pointer text-lg"
                          style={{
                              border: "none",
                              width: "100px"
                          }}
                          onChange={handleDestinationChange}
                          >
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="czech">{t('hero.option_1')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="austria_czech">{t('hero.option_2')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="austria_slovakia_hungary">{t('hero.option_3')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="bulgaria">{t('hero.option_4')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="bulgaria_slovakia_hungary_romania">{t('hero.option_5')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="croatia_slovakia_hungary">{t('hero.option_6')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="croatia_czech_austria_slovakia">{t('hero.option_7')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="croatia_germany_austria_slovenia">{t('hero.option_8')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="croatia_czech_slovakia_hungary">{t('hero.option_18')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="romania">{t('hero.option_9')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="romania_slovakia_hungary">{t('hero.option_10')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="slovakia">{t('hero.option_11')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="slovenia">{t('hero.option_12')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="slovenia_czech_austria">{t('hero.option_13')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="switzerland">{t('hero.option_14')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="switzerland_czech_austria">{t('hero.option_15')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="hungary_slovakia">{t('hero.option_16')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="croatia">{t('hero.option_19')}</option>
                            <option style={{ backgroundColor: "#131313", fontSize: "16px" }} value="italy">{t('hero.option_20')}</option>
                      </select>
                    </div>
                  </div>
                </div>
      
                <div className="border-0 lg:border-r-2 lg:border-b-0 border-b-2 border-[#242226] border-solid lg:p-4 p-6 pt-6 lg:pt-6 flex items-center space-x-4">
                  <Calendar/>
                  <div>
                    <p className="text-[14px] text-neutral leading-[19px] font-[300] mb-1 mt-0">{t('hero.wyjazd')}</p>
                    <div className='flex items-center'>
                        <CustomDatePicker onChange={() => setStartDate(startDate)} value={startDate} isStart={true}/>
                    </div>
                  </div>
                </div>
      
                <div className="border-0 lg:border-r-2 lg:border-b-0 border-b-2 border-[#242226] border-solid lg:p-4 p-6 pt-6 lg:pt-6 flex items-center space-x-4">
                  <Calendar/>
                  <div>
                    <p className="text-[14px] text-neutral leading-[19px] font-[300] mb-1 mt-0">{t('hero.powrot')}</p>
                      <CustomDatePicker onChange={() => setEndDate(endDate)} value={endDate} isStart={false}/>
                  </div>
                </div>
      
                <div className="flex items-center justify-center pb-6 py-2 lg:py-0 lg:pb-0">
                  <button onClick={() => handleSearchClick()} className="no-underline flex items-center text-[16px] font-[700] leading-[21px] text-black bg-accent px-10 py-3 rounded-full border-0 border-solid border-[#FEFA17] hover:bg-yellow-600 hover:border-yellow-400 cursor-pointer transition">
                  {t('hero.szukaj')}
                  </button>
                </div>
              </div>
      
              <div className="flex items-center lg:justify-end justify-center text-neutral text-[14px] font-[500] leading-[19px] mt-6">
                <span className="inline-block mr-2 cursor-pointer" onClick={() => handleGoContact()}><Phone/></span> <span onClick={() => handleGoContact()} className='cursor-pointer'>{t('hero.pomoc')}</span>
              </div>
            </div>
          </section>
        </>
      )
      :
      null
  );
};

export default HeroSection;
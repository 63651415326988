import React, { useEffect, useState } from 'react';
import YellowLine from './YellowLine';
import InfoImage from '../assets/infoImage.webp';
import { useTranslation } from 'react-i18next';

const InfoSection: React.FC = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const { t, i18n } = useTranslation('common');
  useEffect(()=>{
    setIsReady(true);

  }, [i18n.isInitialized]);

  if (!i18n.isInitialized) {
    return null; // Możesz wyświetlić spinner lub placeholder
  }

  return (
    isReady ?
    (
      <section className="px-10 py-12 ">
        <div className='lg:max-w-[1200px] mx-auto flex flex-col md:flex-row items-center md:items-center lg:h-[440px]'>
          <div className="md:w-1/2 text-left pr-6">
            
            <h2 className="text-[45px] font-[700] leading-[61px] text-base-100 mb-4 mt-0">
              {t('infoSection.header1')}
            </h2>
            <p className="text-base-100 font-[400] text-[16px] leading-[35px] mb-6">
            {t('infoSection.text1')}
            </p>
            <a href='/order' className="no-underline items-center text-tiny sm:text-[16px] font-[700] leading-[21px] text-black bg-accent px-2 sm:px-10 py-3 rounded-full border-0 border-solid border-accent hover:bg-yellow-600 hover:border-yellow-600 cursor-pointer transition">
            {t('infoSection.kup')}
            </a>
          </div>

          <div className="hidden md:w-1/2 md:flex justify-center">
            <img src={InfoImage.src} alt="Dekoracyjne zdjęcie" className="w-[90%] h-auto" />
          </div>
        </div>
      </section>
    )
    :
    null
  );
};

export default InfoSection;

export function formatDate(dateString: any) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function calculateDaysBetween(date1: any, date2: any) {
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    
    // Sprawdzenie, czy obie daty są poprawne
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return -1;
    }
    
    // Obliczamy różnicę w milisekundach
    const differenceInTime = Math.abs(endDate.getTime() - startDate.getTime());
    
    // Konwertujemy milisekundy na dni
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    return differenceInDays;
}

export function toLocalISOString(date: any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
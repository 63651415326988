import React from 'react';
import ArrowButton from '@/assets/ArrowButton';
import YellowLine from './YellowLine';
import BenefitCard from './BenefitCard';
import MobileIcon from '@/assets/MobileIcon';
import { useTranslation } from 'react-i18next';

const TravelSection: React.FC = () => {
  const { t } = useTranslation('common');

  return (
    <section className="py-12 lg:max-w-[1200px] min-h-[450px] p-6 lg:p-0 mx-auto">
    <div className="lg:flex lg:justify-between lg:items-center mb-6 lg:space-x-12 space-y-10 lg:space-y-0">
      <div className="flex flex-col">
          <h2 className="text-[45px] font-[700] leading-[61px] text-base-100 mb-3 mt-0 min-h-[61px]">{t('travelSection.header1')}</h2>
          <YellowLine/>
      </div>
      <div className="lg:w-1/2">
        <p className="text-base-100 text-[16px] leading-[35px] min-h-[35px]">{t('travelSection.text1')}</p>
      </div>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mx-auto min-h-[450px]">
      <BenefitCard icon={<MobileIcon />} higher={true} title={t('travelSection.card1h')} description={t('travelSection.card1t')} />
      <BenefitCard icon={<MobileIcon />} higher={false} title={t('travelSection.card2h')} description={t('travelSection.card2t')} />
      <BenefitCard icon={<MobileIcon />} higher={true} title={t('travelSection.card3h')} description={t('travelSection.card3t')} />
      <BenefitCard icon={<MobileIcon />} higher={false} title={t('travelSection.card4h')} description={t('travelSection.card4t')} />
    </div>
  </section>
  );
};

export default TravelSection;

import Switcher from "@/assets/Switcher";
import { useEffect, useState } from "react";

const BtnDarkMode: React.FC = () => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') : 'customDark');

  // Załaduj temat z localStorage przy pierwszym renderze
  useEffect(() => {
    const t = localStorage.getItem('theme');
    setTheme(t ? t : 'customDark');
  }, []);

  const handleToggle = () => {
    setTheme(prevTheme => prevTheme === 'customDark' ? 'customLight' : 'customDark');
  };

  // Zapisz temat do localStorage i zaktualizuj atrybut html
  useEffect(() => {
    localStorage.setItem('theme', theme ? theme : '');
    document.querySelector('html')?.setAttribute('data-theme', theme ? theme : '');
  }, [theme]);

  return (
    <div className='max-h-[22px]' onClick={handleToggle}>
      <label className='swap swap-rotate'>
        {/* Kontrolowany checkbox */}
        <input
          type='checkbox'
          className='theme-controller'
          checked={theme === 'customLight'} // Synchronizacja stanu z checkboxem
          onChange={handleToggle} // Dodaj obsługę onChange
        />
        <Switcher className='fill-base-100' />
      </label>
    </div>
  );
};

export default BtnDarkMode;

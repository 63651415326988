import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) // Ładowanie tłumaczeń z backendu (asynchroniczne)
  .use(LanguageDetector) // Wykrywanie języka z przeglądarki/localStorage
  .use(initReactI18next) // Integracja z React
  .init({
    //lng: 'pl',
    supportedLngs: ['pl', 'en', 'de'], // Obsługiwane języki
    backend: {
      loadPath: '/locales/{{lng}}/common.json', // Ścieżka do plików tłumaczeń
    },
    detection: {
      order: ['localStorage', 'navigator'], // Kolejność wykrywania języka
      caches: ['localStorage'], // Zapis języka w localStorage
    },
    interpolation: {
      escapeValue: false, // Wyłącz escapowanie (bezpieczne dla React)
    },
    fallbackLng: 'pl', // Język domyślny
    debug: false, // Wyłącz debugowanie (ustaw na true, jeśli coś nie działa)
  });

export default i18n;

import React, { useState } from 'react';
import Logo from '../assets/logoMini.png';
import GrayLocalization from '@/assets/GrayLocalization';
import GrayTime from '@/assets/GrayTime';
import GrayMail from '@/assets/GrayMail';
import FooterCall from '@/assets/FooterCall';
import FooterChat from '@/assets/FooterChat';
import FooterMail from '@/assets/FooterMail';
import Facebook from '@/assets/Facebook';
import Youtube from '@/assets/Youtube';
import Dribble from '@/assets/Dribble';
import Figma from '@/assets/Figma';
import WhatsApp from '@/assets/WhatsApp';
import { toast } from 'react-toastify';
import axios from '@/axiosConfig';
import TikTok from '@/assets/TikTokIcon';
import { isValidEmail } from '@/utils/validationFunctions';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation('common');
  const [email, setEmail] = useState("");

  const onNewsletterSubmit = async (e: any) => {
    e.preventDefault();
    try
    {
      if(email == '' || !isValidEmail(email))
      {
        toast.error('Uzupełnij poprawnie email zanim spróbujesz się zapisać');
        return;
      }
      const response = await toast.promise(axios.post("/Newsletter", {
        email
      }), {
        success: "Zapisano do newslettera",
        error: "Nie udało się zapisać",
        pending: "Zapisywanie..."
      })
    }
    catch(error) {

    }
  }

  return (
    <footer className="py-6 pt-24 px-8 bg-primary">
      <div className="max-w-7xl mx-auto space-y-12">
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="space-y-4">
            <div className='mb-6 lg:mb-0' id='footer'>
              <img src={Logo.src} alt="Logo winiety24.eu" className="w-36" />
              <p className="text-[14px] font-[400] leading-[19px] text-base-200 lg:w-3/4">
              {t('footer.text1')}
              </p>
            </div>
            <div>
              <div className='flex mt-4'>
                <div className='flex items-center pr-2'>
                    <GrayLocalization/>
                </div>
                <div className='flex flex-col'>
                  <p className='mb-0 font-[400] text-neutral text-[12px] leading-[16px] mt-0'>{t('footer.siedziba')}</p>
                  <p className='mt-1 mb-0 font-[400] text-base-100 text-[16px] leading-[16px]'>ul. 11 listopada 79, 44-330 Jastrzębie-Zdrój</p>
                </div>
              </div>
              <div className='flex mt-4'>
                <div className='flex items-center pr-2'>
                    <GrayTime/>
                </div>
                <div className='flex flex-col'>
                  <p className='mb-0 font-[400] text-neutral text-[12px] leading-[16px] mt-0'>{t('footer.godziny')}</p>
                  <p className='mt-1 mb-0 font-[400] text-base-100 text-[16px] leading-[16px]'>9:00 - 17:00</p>
                </div>
              </div>
              <div className='flex mt-4'>
                <div className='flex items-center pr-2'>
                    <GrayMail/>
                </div>
                <div className='flex flex-col'>
                <p className='mb-0 font-[400] text-neutral text-[12px] leading-[16px] mt-0'>{t('footer.kontakt')}</p>
                <p className='mt-1 mb-0 font-[400] text-base-100 text-[16px] leading-[16px]'>bok@winiety24.eu</p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 md:gap-0 gap-6 md:mt-0 mt-6">
            <div className='lg:w-3/4 lg:pl-6 lg:text-start text-center'>
              <h4 className="text-base-100 font-[400] text-[16px] leading-[21px] mb-4">{t('footer.firma')}</h4>
              <ul className="list-none pl-0 space-y-2 text-sm">
              <li><a href="/o-nas" className="hover:underline no-underline text-base-200 font-[300] text-[14px] leading-[24px]">{t('footer.about')}</a></li>
                <li><a href="/regulamin" className="hover:underline no-underline text-base-200 font-[300] text-[14px] leading-[24px]">{t('footer.regulamin')}</a></li>
                <li><a href="/polityka-prywatnosci" className="hover:underline no-underline text-base-200 font-[300] text-[14px] leading-[24px]">{t('footer.polityka')}</a></li>
                <li><a href="/kontakt" className="hover:underline no-underline text-base-200 font-[300] text-[14px] leading-[24px]">{t('footer.kontakt2')}</a></li>
                <li><a href="/blog" className="hover:underline no-underline text-base-200 font-[300] text-[14px] leading-[24px]">{t('footer.blog')}</a></li>
              </ul>
            </div>
            <div className='lg:w-3/4 lg:text-start text-center'>
              <h4 className="text-base-100 font-[400] text-[16px] leading-[21px] mb-4">{t('footer.winieta')}</h4>
              <ul className="list-none pl-0 space-y-2 text-sm">
                <li><a href="/faq" className="hover:underline no-underline text-base-200 font-[300] text-[14px] leading-[24px]">{t('footer.pytania')}</a></li>
                <li><a href="/regulamin" className="hover:underline no-underline text-base-200 font-[300] text-[14px] leading-[24px]">{t('footer.czas')}</a></li>
                <li><a href="/regulamin" className="hover:underline no-underline text-base-200 font-[300] text-[14px] leading-[24px]">{t('footer.formy')}</a></li>
              </ul>
            </div>
            <div className='w-full'>
              <h4 className="text-base-100 font-[400] text-[16px] leading-[21px] mb-4 lg:text-start text-center">{t('footer.newsletter')}</h4>
              <p className="text-base-200 font-[300] text-[14px] leading-[24px] mb-6 lg:text-start text-center">
              {t('footer.text2')}
              </p>
              <form className="flex border-[2px] border-solid border-[#242226] rounded-[52px] p-2">
                <input
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  type="email"
                  placeholder=" E-mail"
                  className="bg-transparent border-0 focus:outline-none text-base-100 w-3/4"
                />
                <button
                  type="submit"
                  className="rounded-[52px] text-base-100 text-nowrap py-3 bg-primary font-[700] text-[16px] leading-[21px] border-0 px-5 ml-2 cursor-pointer hover:bg-gray-300 hover:text-black transition"
                  onClick={async (e) => await onNewsletterSubmit(e)}
                >
                  {t('footer.zapisz')}
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 border-2 rounded-[25px] border-[#242226] mx-6 lg:mx-0 border-solid md:grid-cols-3 gap-4">
          <div className="border-0 lg:border-r-2 lg:border-b-0 border-b-2 border-[#242226] border-solid lg:p-6 p-6 py-6 lg:py-8 flex items-center space-x-4">
            <div className='w-auto'>
              <FooterCall/>
            </div>
            <div>
              <p className="text-[14px] text-neutral leading-[20px] font-[400] mb-0 mt-0">{t('footer.zadzwon')}</p>
              <h3 className="md:text-[25px] text-[16px] text-base-100 leading-[36px] font-[600] mb-0 mt-0">+48 796 683 007</h3>
            </div>
          </div>

          <div className="border-0 lg:border-r-2 lg:border-b-0 border-b-2 border-[#242226] border-solid lg:p-6 p-6 py-6 lg:py-8 flex items-center space-x-4">
            <div className='w-auto'>
              <FooterChat/>
            </div>
            <div>
              <p className="text-[14px] text-neutral leading-[20px] font-[400] mb-0 mt-0">{t('footer.dolacz')}</p>
              <h3 className="md:text-[25px] text-[16px] text-base-100 leading-[29px] font-[600] mb-0 mt-0">{t('footer.program')}</h3>
            </div>
          </div>

          <div className="border-0 border-b-0 border-[#242226] border-solid lg:p-6 p-6 py-6 lg:py-8 flex items-center space-x-4">
            <div className='w-auto'>
              <FooterMail/>
            </div>
            <div>
              <h3 className="md:text-[25px] text-[16px] text-base-100 leading-[36px] font-[600] mb-0 mt-0">{t('footer.wyslij')}</h3>
              <p className="text-[14px] text-neutral leading-[20px] font-[400] mb-0 mt-0 w-6/7">{t('footer.skontaktuj')}</p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:justify-between justify-center items-center pt-6">
          <p className='text-center md:text-start text-base-200 font-[400] text-[14px] leading-[19px]'>2024 Prospect Group. All rights reserved.</p>
          <div className="flex space-x-4 mt-4 md:mt-0">
            <a href="https://www.facebook.com/profile.php?id=61567364451625">
              <Facebook className='stroke-base-100'/>
            </a>
            <a href='https://www.tiktok.com/@winiety_24.eu'>
              <TikTok className='stroke-base-100'/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import AccentButton from "./AccentButton";
import GrayButton from "./GrayButton";
import SelectField from "./SelectField";
import PL from "../assets/poland.png";
import CZ from "../assets/czechy.png";
import AU from "../assets/austria.png";
import { t } from "i18next";


interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSbmt: (lang: any) => void;
}

const RegionalPreferencesModal: React.FC<ModalProps> = ({ isOpen, onClose, onSbmt }) => {
  if (!isOpen) return null;

  const [language, setLanguage] = useState("pl");



  const onSbmtHandler = () => {
    onSbmt(language);
  }

  useEffect(() => {
    if(localStorage.getItem('i18nextLng') != null)
    {
      let lang = localStorage.getItem('i18nextLng');
      setLanguage(lang ? lang : 'pl');
    }
  }, [])

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-secondary text-base-100 p-6 rounded-3xl shadow-lg w-[90%] max-w-xs relative">
        <button
          onClick={onClose}
          className="absolute cursor-pointer top-3 right-4 bg-transparent border-0 text-[25px] text-gray-400 hover:text-base-100"
          aria-label="Zamknij"
        >
          ✕
        </button>

        <h2 className="text-2xl text-center font-bold pt-4 mb-2">{t('preferences.h2_1')}</h2>
        <p className="text-sm text-center text-gray-400 mb-4">
          {t('preferences.p_1')}
        </p>

        <div className="space-y-6">
          {/* Region */}
          

          {/* Język */}
          <div>
            <label className="block text-sm mb-2 ml-4">{t('preferences.label_1')}</label>
            <SelectField value={language} onChange={(e) => setLanguage(e.target.value)}>
                <option value="pl">{t('preferences.option_1')}</option>
                <option value="de">{t('preferences.option_2')}</option>
                <option value="en">{t('preferences.option_3')}</option>
            </SelectField>
          </div>

          {/* Waluta */}
          
        </div>

        <div className="mt-6 w-full">
          <AccentButton text={t('preferences.button_1')} classes="w-full mb-4" withArrow={false} onClick={() => {onSbmtHandler(); onClose()}}/>
          <GrayButton text={t('preferences.button_2')} withArrow={false} classes="w-full" textClass="w-full text-center" onClick={onClose}/>
        </div>
      </div>
    </div>
  );
};

export default RegionalPreferencesModal;

import React from 'react';
import InfoImage from '../assets/platnosci/przelewy.webp';

import InfoImage1 from '../assets/platnosci/apay.webp';
import InfoImage2 from '../assets/platnosci/gpay.webp';
import InfoImage3 from '../assets/platnosci/master.webp';
import InfoImage4 from '../assets/platnosci/visa.webp';
import InfoImage5 from '../assets/platnosci/blik.webp';

const PaymentSection: React.FC = () => {
  return (
    <section className="px-10 py-12">
      <div className="lg:max-w-[1550px] mx-auto flex flex-col items-center">
        <div className="flex flex-col items-center">
          <div>
            <img src={InfoImage.src} alt="Forma płatności" className="mx-auto w-[197px] h-[91px]" />
          </div>
        </div>
        <hr className="lg:w-1/2 w-full" />
        <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-6 lg:space-x-6 items-center justify-center">
            <img src={InfoImage1.src} alt="Forma płatności 1" className='w-[132px] h-[79px]'/>
            <img src={InfoImage2.src} alt="Forma płatności 2" className='w-[94px] h-[51px]'/>
            <img src={InfoImage3.src} alt="Forma płatności 3" className='w-[86px] h-[53px]'/>
            <img src={InfoImage4.src} alt="Forma płatności 4" className='w-[139px] h-[47px]'/>
            <img src={InfoImage5.src} alt="Forma płatności 5" className='w-[113px] h-[58px]'/>
        </div>
      </div>
    </section>
  );
};

export default PaymentSection;

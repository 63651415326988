import { t } from "i18next";
import React, { useRef, useState } from "react";
import miniaturka from '../assets/miniaturka.webp';

const VideoSection: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPlayIcon, setShowPlayIcon] = useState(true);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
      setShowPlayIcon(false);
    }
  };

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
        setShowPlayIcon(true);
      } else {
        videoRef.current.play();
        setShowPlayIcon(false);
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <section className="pb-12 lg:max-w-[1200px] px-6 lg:p-0 mx-auto">
        <div className="flex justify-center items-center ">
        <div className="flex flex-col">
            <h2 className="text-[45px] text-center font-[700] leading-[61px] text-base-100 mb-6">{t('video.header1')}</h2>
            {/* <p className="text-base-100 text-[16px] leading-[35px]">{t('blogSection.text1')}</p> */}
        </div>
      </div>
      <div className="relative w-full overflow-hidden rounded-xl">
        <video
          ref={videoRef}
          className="w-full rounded-xl cursor-pointer"
          poster={miniaturka.src}
          onClick={handleVideoClick}
        >
          <source
            src="https://api.winiety24.eu/videos/Winiety24Compresed.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        {showPlayIcon && (
          <div
            onClick={togglePlayPause}
            className="absolute inset-0 flex items-center justify-center bg-black/50 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.752 11.168l-4.586-2.664A1 1 0 009 9.42v5.16a1 1 0 001.166.983l4.586-2.664a1 1 0 000-1.736z"
              />
            </svg>
          </div>
        )}
      </div>
    </section>
  );
};

export default VideoSection;

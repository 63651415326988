import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.winiety24.eu/', // Zamień na adres swojego API
  //baseURL: 'http://localhost:5238',
  //baseURL: 'http://195.62.12.48:5005',
  //baseURL: 'http://51.68.143.33:5002',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;
